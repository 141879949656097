// import Cesium from "./core"
import wkt from 'terraformer-wkt-parser'

export function transformWGS842Cartesian(position, alt) {
  return position ? Cesium.Cartesian3.fromDegrees(position.lng, position.lat, (position.alt = alt || position.alt), Cesium.Ellipsoid.WGS84) : Cesium.Cartesian3.ZERO
}

export function transformCartesian2WGS84(cartesian) {
  let ellipsoid = Cesium.Ellipsoid.WGS84
  let cartographic = ellipsoid.cartesianToCartographic(cartesian)
  return {
    lng: Cesium.Math.toDegrees(cartographic.longitude),
    lat: Cesium.Math.toDegrees(cartographic.latitude),
    alt: cartographic.height
  }
}

export function cartesian3PointToWGS84(cartesian) {
  let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
  return {
    lng: Cesium.Math.toDegrees(cartographic.longitude),
    lat: Cesium.Math.toDegrees(cartographic.latitude),
    alt: cartographic.height
  }
}

export function getCartesian3FromPX(viewer, px) {
  // debugger
  let picks = viewer.scene.drillPick(px)
  let isOn3dtiles = false,
    isOnTerrain = false,
    cartesian = null

  for (let i in picks) {
    let pick = picks[i]

    if (pick && (pick.primitive instanceof Cesium.Cesium3DTileFeature || pick.primitive instanceof Cesium.Cesium3DTileset || pick.primitive instanceof Cesium.Model)) {
      isOn3dtiles = true
    }

    //is on 3dtiles
    if (isOn3dtiles) {
      viewer.scene.pick(px)
      cartesian = viewer.scene.pickPosition(px)
      if (cartesian) {
        let cartographic = Cesium.Cartographic.fromCartesian(cartesian)
        if (cartographic.height < 0) cartographic.height = 0
        let lng = Cesium.Math.toDegrees(cartographic.longitude)
        let lat = Cesium.Math.toDegrees(cartographic.latitude)
        let alt = cartographic.height
        cartesian = transformWGS842Cartesian({ lng, lat, alt })
      }
    }
  }

  // is ellipsoid terrain
  let boolTerrain = viewer.terrainProvider instanceof Cesium.EllipsoidTerrainProvider

  // is other terrain
  if (!isOn3dtiles && !boolTerrain) {
    let ray = viewer.scene.camera.getPickRay(px)
    if (!ray) return null
    cartesian = viewer.scene.globe.pick(ray, viewer.scene)
    isOnTerrain = true
  }

  if (!isOn3dtiles && !isOnTerrain && boolTerrain) {
    cartesian = viewer.scene.camera.pickEllipsoid(px, viewer.scene.globe.ellipsoid)
  }

  // 避免位置陷入地下
  if (cartesian) {
    let position = transformCartesian2WGS84(cartesian)
    if (position.alt < 0) {
      cartesian = transformWGS842Cartesian(position, 0.1)
    }
  }

  return cartesian
}

//geom转position
export function geomToCartesian3(geom) {
  let positions = []
  let geojson = wkt.parse(geom)
  let { type, coordinates } = geojson
  if (type === 'Point') {
    let [x, y, z] = coordinates
    positions.push(Cesium.Cartesian3.fromDegrees(x, y, z))
  } else if (type === 'LineString') {
    coordinates.forEach((element) => {
      let [x, y, z] = element
      positions.push(Cesium.Cartesian3.fromDegrees(x, y, z))
    })
  } else if (type === 'Polygon') {
    coordinates.forEach((element) => {
      element.forEach((item) => {
        let [x, y, z] = item
        positions.push(Cesium.Cartesian3.fromDegrees(x, y, z))
      })
    })
  }
  return {
    type,
    positions
  }
}

/**
 * 将单个 Cartesian3 坐标转换为 WGS84 坐标
 *
 * @export
 * @param {*} position
 * @returns
 */
export function simpleCartesian3ToWGS84(position, viewer) {
  let ellipsoid = viewer.scene.globe.ellipsoid
  let cartographic = ellipsoid.cartesianToCartographic(position)
  //弧度转经度
  let wgs84 = {}
  wgs84.longitude = Cesium.Math.toDegrees(cartographic.longitude)
  wgs84.latitude = Cesium.Math.toDegrees(cartographic.latitude)
  wgs84.altitude = cartographic.height
  return wgs84
}

// 点位转WKTString
export function positionToGeom(wgs84Obj) {
  const { type, positions } = wgs84Obj
  let geom = ''
  if (type === 'Point') {
    geom = wkt.convert({
      type: 'Point',
      coordinates: positions[0]
    })
  } else if (type === 'LineString') {
    geom = wkt.convert({
      type: 'LineString',
      coordinates: positions
    })
  } else if (type === 'Polygon') {
    geom = wkt.convert({
      type: 'Polygon',
      coordinates: [positions]
    })
  }
  return geom
}

// Cartesian3ToWGS84
export function cartesian3ToWGS84(viewer, cartesian3Obj) {
  const { type, positions } = cartesian3Obj
  let wgs84Positions
  if (type === 'Point') {
    wgs84Positions = Array.prototype.map.call(positions, (item) => {
      let wgs84 = simpleCartesian3ToWGS84(item, viewer)
      return [wgs84.longitude, wgs84.latitude, wgs84.altitude]
    })
  } else if (type === 'LineString') {
    wgs84Positions = Array.prototype.map.call(positions, (item) => {
      let wgs84 = simpleCartesian3ToWGS84(item, viewer)
      return [wgs84.longitude, wgs84.latitude, wgs84.altitude]
    })
  } else if (type === 'Polygon') {
    wgs84Positions = Array.prototype.map.call(positions, (item) => {
      let wgs84 = simpleCartesian3ToWGS84(item, viewer)
      return [wgs84.longitude, wgs84.latitude, wgs84.altitude]
    })
    wgs84Positions.push(wgs84Positions[0])
  }
  return {
    type,
    positions: wgs84Positions
  }
}

export function addZero(num) {
  if (parseInt(num) < 10) {
    num = '0' + num
  }
  return num
}

export async function initLocation2WUHU() {
  // 初始化定位芜湖市！！！
  await window.viewer.camera.flyTo({
    // destination: Cesium.Cartesian3.fromDegrees(120.218241, 33.165767, 21812.321476599747),
    destination: Cesium.Cartesian3.fromDegrees(118.22073, 29.50576, 151812.321476599747),
    orientation: {
      heading: 0.006918732929549698,
      pitch: -0.6928665494487145,
      roll: 7.638334409421077e-14
    },
    time: 5
  })
}
/**@Description :  ******************************************* 根据经纬度，计算地形点高度
 **@Date: 2024-06-20 09:44:40
*/
export function caculateHeightByTerrain(viewer, lon, lat) {
  let h1 = viewer.scene.globe.getHeight(Cesium.Cartographic.fromDegrees(parseFloat(lon), parseFloat(lat)));
  return h1;
}
/**@Description :  ******************************************* 生成 HTMLCanvasElement
 **@Date: 2024-07-08 14:45:50
*/
export function createHTMLCanvasElement(text, radius, startBgColor, endBgColor, strokeColor) {
  let myConvas = document.createElement("canvas");
  let scale = 2.5;
  let context = myConvas.getContext('2d');
  let textWidth = context.measureText(text).width * 4;
  let textHeight = 70;
  myConvas.width = textWidth;
  myConvas.height = textHeight;
  roundRect(context, 0, 0, textWidth, textHeight, radius, startBgColor, endBgColor, strokeColor)
  //填充文字
  let fontSize = 14 * scale
  context.font = fontSize + "px 'Microsoft Yahei'";
  context.fillStyle = "#ffffff";
  context.textAlign = 'center';
  context.textBaseline='middle';
  context.fillText(text, myConvas.width / 2, myConvas.height / 2 );
  context.stroke();

  context.imageSmoothingEnabled = true;
  return myConvas.toDataURL('image/png');
}
function roundRect(context, x, y, width, height, radius, startBgColor, endBgColor, strokeColor) {
  // width = width + 2*radius;
  // 开始绘制
  context.beginPath();
  context.moveTo(x + radius, y);

  context.lineTo(x + width - radius, y);
  context.quadraticCurveTo(x + width, y, x + width, y + radius);

  context.lineTo(x + width, y + height - radius);
  context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);

  context.lineTo(x + radius, y + height);
  context.quadraticCurveTo(x, y + height, x, y + height - radius);

  context.lineTo(x, y + radius);
  context.quadraticCurveTo(x, y, x + radius, y);

  // 填充渐变色
  let gradientBar = context.createLinearGradient(0, 0, 0, width);
  gradientBar.addColorStop(0, startBgColor);
  gradientBar.addColorStop(1, endBgColor);
  context.strokeStyle = strokeColor;
  context.lineWidth = 6.0;
  context.fillStyle = gradientBar;
  context.closePath();
  context.fill();
}


export default {
  transformWGS842Cartesian,
  transformCartesian2WGS84,
  getCartesian3FromPX,
  initLocation2WUHU,
  simpleCartesian3ToWGS84,
  cartesian3PointToWGS84,
  caculateHeightByTerrain
}
